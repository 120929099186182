<div class="row m-0 mb-3">
  <div class="col-12">
    <div *ngIf="filterList | async as facet">
      <div class="d-flex flex-column" *ngIf="facet?.facets?.length > 0; else noFacets">
        <ng-container *ngIf="(facet?.facets?.at(0)?.name === 'compatibility'); else noTurbine">
          <div class="turbineFilters">
            <h3 class="mb-2">{{"psp.turbine"| cxTranslate }}</h3>
            <input type="text" placeholder="{{'psp.filterTurbines'| cxTranslate }}" (input)="updateTurbines($event)"
              class="form-control my-2 rounded-0">
            <div *ngIf="facet?.facets?.at(0)?.values as turbines">
              <div class="form-check form-check-container"
                *ngFor="let turbine of turbines | filterDropdown : searchText | categoryToggleFilter: turbineToggle; index as i">
                <input type="checkbox" id="turbine-0" class="form-check-input" value="{{turbine.name}}"
                  [checked]="turbine.selected"
                  (change)="valueChange(':compatibility:', turbine.name, turbine.selected)">
                <label class="form-check-label">
                  {{turbine.name}}
                  <span *ngIf="!(userLoggedIn | async)" class="gray"> ({{turbine.count}}) </span>
                </label>
              </div>
              <ng-container *ngIf="!turbineToggle; else showLess">
                <a *ngIf="facet?.facets?.at(0)?.values?.length >= 8" class="pl-1 text-decoration-none">
                  <i class="fa fa-caret-down toggle-show"></i>
                  <span class="gray show-all-label" (click)="filterToggle('turbine')">{{"psp.showAll"|
                    cxTranslate}}</span>
                </a>
              </ng-container>
              <ng-template #showLess>
                <a class="pl-1 text-decoration-none">
                  <i class="fa fa-caret-up toggle-show"></i>
                  <span class="gray show-all-label" (click)="filterToggle('turbine')">{{"psp.showLess"| cxTranslate
                    }}</span>
                </a>
              </ng-template>
            </div>
          </div>
        </ng-container>
        <!-- Categories Filter -->
        <ng-container *ngIf="getCategoryIndex(facet?.facets) != -1; else noCategory">
          <div class="categoryFilters">
            <h3 class="mb-2">{{'psp.category' | cxTranslate }}</h3>
            <div *ngIf="(facet?.facets?.at(getCategoryIndex(facet?.facets)).values) as categories">
              <div class="form-check form-check-container" *ngFor="let category of categories">
                <input type="checkbox" id="category-0" class="form-check-input" value="{{category.name}}"
                  [checked]="category.selected"
                  (change)="valueChange(':allCategories:', category.name, category.selected)">
                <label class="form-check-label">
                  {{ category.name }}
                  <span *ngIf="!(userLoggedIn | async)" class="gray">({{category.count}})</span>
                </label>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <ng-template #noFacets>
        <ng-container *ngTemplateOutlet="noTurbine"></ng-container>
        <ng-container *ngTemplateOutlet="noCategory"></ng-container>
      </ng-template>
      <ng-template #noTurbine>
        <div class="turbineFilters">
          <h3 class="mb-2">{{"psp.turbine"| cxTranslate }}</h3>
          <input type="text" placeholder="Filter Turbines" (input)="updateTurbines($event)" class="form-control my-2 rounded-0">
        </div>
      </ng-template>
      <ng-template #noCategory>
        <div class="categoryFilters">
          <h3 class="mb-2">{{'psp.category' | cxTranslate }}</h3>
        </div>
      </ng-template>
    </div>
  </div>
</div>