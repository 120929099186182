import { Pipe, PipeTransform } from '@angular/core';
import { ValueOfFacets } from '../models/plpInfo';

@Pipe({
  name: 'categoryToggleFilter'
})
export class CategoryToggleFilterPipe implements PipeTransform {

  transform(data: ValueOfFacets[], categoryToggle = false): ValueOfFacets[] {
    if (!data || data.length === 0) {
      return null;
    }
    return categoryToggle
      ? data
      : data.slice(0, 8);
  }

}
